import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ElectivePage from "../views/ElectivePage.vue";
import aboutView from "../views/AboutView.vue";
import ProgramView from "../views/ProgramView.vue";
import shoreView from "@/views/ShoreView.vue";
import ContactView from "../views/ContactView.vue";
import BlogView from "@/views/BlogView.vue";
import BlogsDetail from "../views/BlogsDetail.vue";
import RefundPolicy from "../views/RefundPolicy.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import MandatoryDisclosure from "../views/MandatoryDisclosure.vue";
import AcademicResources from "../views/AcademicResources.vue";
import SearchView from "../views/SearchView.vue";
import lpProgramm from "@/views/lpPages/lpProgramm.vue";
import lpProgrammThankyou from "@/views/lpPages/lpProgrammThankyou.vue";
import lpProgram from "@/views/lpPages/lpProgram.vue";
import PageNotFoundView from "@/views/PageNotFoundView";
import FeedbackView from "@/views/FeedbackView";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:program_slug/:slug",
    name: "ElectivePage",
    component: ElectivePage,
    props: true,
  },
  {
    path: "/about-us",
    name: "about",
    component: aboutView,
  },
  {
    path: "/:slug",
    name: "programPage",
    component: ProgramView,
    props: true,
  },
  {
    path: "/contact-us",
    name: "ContactView",
    component: ContactView,
  },
  {
    path: "/shore",
    name: "shore",
    component: shoreView,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: BlogView,
  },
  {
    path: "/blogs/:slug",
    name: "BlogsDetail",
    component: BlogsDetail,
    props: true,
  },
  {
    path: "/refund",
    name: "RefundPolicy",
    component: RefundPolicy,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/mandatory-disclosure",
    name: "MandatoryDisclosure",
    component: MandatoryDisclosure,
  },
  {
    path: "/academic-resources",
    name: "AcademicResources",
    component: AcademicResources,
  },
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: FeedbackView,
  },
  {
    path: "/lp/:slug",
    name: "LP_Programs",
    component: lpProgramm,
    props: true,
  },
  {
    path: "/lp/test",
    name: "LP_Program",
    component: lpProgram,
    props: true,
  },

  {
    path: "/lp/:slug/thankyou",
    name: "LP_ProgramsThankyou",
    component: lpProgrammThankyou,
    props: true,
  },
// redirection
  {
    path: "/online-mba/international-business",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/logistics-and-supply-chain-management",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/operations-management",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/project-management",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/digital-marketing-and-ecommerc",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/international-finance",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/data-science-and-analytics",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mca/artificial-intelligence",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/online-mca/cloud-computing",
    redirect: "/masters-computer-applications-online-degree",
  },
  {
    path: "/online-mca/cyber-security",
    redirect: "/masters-computer-applications-online-degree",
  },
  // {
  //   path: "/master-of-commerce-online-degree",
  //   redirect: "/",
  // },
  // {
  //   path: "/online-mcom/accounting-and-finance",
  //   redirect: "/",
  // },
  // {
  //   path: "/online-mcom/international-finance",
  //   redirect: "/",
  // },
  // {
  //   path: "/bachelor-computer-application-online-degree",
  //   redirect: "/",
  // },
  // {
  //   path: "/online-bca/online-bca-program",
  //   redirect: "/",
  // },
  // {
  //   path: "/bachelors-business-administration-online-degree",
  //   redirect: "/",
  // },
  // {
  //   path: "/online-bba/online-bba-program",
  //   redirect: "/",
  // },
  {
    path: "/online-bba/general-management",
    redirect: "/",
  },
  // {
  //   path: "/online-bba-program",
  //   redirect: "/",
  // },
  {
    path: "/online-mba/international-finance-mba",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/online-mba/operation-management",
    redirect: "/masters-business-administration-online-degree",
  },
  {
    path: "/refundpolicy",
    redirect: "/refund",
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
