<template>
  <div class="px-5 lg:px-8">
    <div class="max-w-6xl mx-auto py-10">
      <h2 class="py-4 text-[#18479E] leading-tight text-[30px] lg:text-[60px] font-normal text-left lg:text-center">
        How <span class="text-[#ec027c]">to Enroll</span>
      </h2>

      <div class="flex flex-col gap-5 lg:gap-0">
        <div
          class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky">
          <div>
            <h2 class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left">
              Step 1
            </h2>
            <p class="text-[14px] lg:text-[18px] text-white text-center lg:text-left">
              <a :href="apply_url"
                 class="underline">
                Click here
              </a>
              to visit the Sharda Online
            </p>
            <p class="text-[14px] lg:text-[18px] text-white text-center lg:text-left">
              Enrollment Form
            </p>
          </div>
          <img class="w-[150px] h-[150px]" src="@/assets/elective/enroll/Eminent Educators copy 15@2x.png"
            alt="element icon" />
        </div>
        <div
          class="p-10 bg-white flex-col items-center flex lg:flex-row lg:justify-between gap-2 rounded-lg border content--sticky">
          <img class="w-[150px] h-[150px]" src="@/assets/elective/enroll/Access to Exclusive Resources@2x.png"
            alt="element icon" />
          <div>
            <h2
              class="text-[#18479E] text-[20px] lg:text-[30px] font-medium text-center whitespace-nowrap lg:text-right">
              Step 2
            </h2>
            <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
              Sign up and complete the four essential steps to enroll
            </p>
            <p class="text-[14px] lg:text-[18px] text-center lg:text-right">
              in the program of your choice
            </p>
          </div>
        </div>
        <div
          class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg content--sticky">
          <div>
            <h2 class="text-[#0CB1EF] text-[20px] lg:text-[30px] font-medium text-center lg:text-left">
              Step 3
            </h2>
            <p class="text-[14px] lg:text-[18px] text-white text-center lg:text-left">
              Pay the fee through Net Banking/Debit card/Credit card
            </p>
            <p v-if="$route.path !== '/online-pgd/banking-and-financial-services'" class="text-[14px] lg:text-[18px] text-white text-center lg:text-left">
              or using easy EMI with 0% interest option
            </p>
          </div>
          <img class="w-[150px] h-[150px]" src="@/assets/elective/enroll/Eminent Educators copy 14@2x.png"
            alt="element icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnrollView",
  data() {
    return {
      apply_url: "https://apply.shardaonline.ac.in/"
    }
  },
  created() {
    if (this.$route.path == '/online-mcom/public-accounting') {

      this.apply_url = "https://apply.shardaonline.ac.in/mcom"

    }
    else if (this.$route.path == '/online-mba/sales-and-marketing') {

      this.apply_url = "https://apply.shardaonline.ac.in/mbsm"

    } else if (this.$route.path == '/online-pgd/banking-and-financial-services') {

      this.apply_url = "https://apply.shardaonline.ac.in/pgd"

    } else {
      this.apply_url = "https://apply.shardaonline.ac.in/"
    }
  },
};
</script>

<style scoped>
.content--sticky {
  position: sticky;
  top: 20%;
  padding-top: 3rem;
  transition: 0.3s ease;
}
</style>
