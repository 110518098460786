<template>
  <div v-if="$route.path !== '/online-pgd/banking-and-financial-services'" class="w-full bg-[#18479E] px-5 lg:px-8">
    <div class="max-w-7xl mx-auto py-10 lg:py-0">
      <div class="max-w-5xl mx-auto">
        <div class="flex flex-col min-h-[610px] justify-center">
          <h2 class="text-[30px] lg:text-[60px] font-normal mb-3">
            <span class="text-white">Career </span
            ><span class="text-[#0AB1EF]">Advancement </span
            ><span class="text-white">Services</span>
          </h2>
          <div>
            <div class="flex gap-[20px] lg:gap-[20px] overflow-x-auto pb-3">
              <button
                v-for="career in careers"
                :key="career"
                @click="selectedcareer = career"
                :class="{
                  ' border-b border-white text-white font-semibold ':
                    selectedcareer === career,
                  ' border-b  text-[#0CB1EF] border-[#0CB1EF] font-medium ':
                    selectedcareer !== career,
                }"
                class="text-center pb-1 text-[16px] lg:text-[18px] xl:text-[22px] text-[#0CB1EF] font-normal whitespace-nowrap"
              >
                {{ career }}
              </button>
            </div>
            <div class="py-4" v-if="selectedcareer === 'Career Support'">
              <div class="flex flex-col gap-5">
                <h2
                  class="text-[20px] lg:text-[30px] text-[#0CB1EF] font-medium lg:font-semibold"
                >
                  Career Support
                </h2>
                <p class="text-white text-[16px]">
                  We’re not only here for the course of your academic journey.
                  We’ve got you covered all the way through!
                </p>
                <div
                  class="flex flex-col gap-4 lg:flex-row lg:gap-[80px] lg:items-center"
                >
                  <div>
                    <img
                      src="@/assets/home/career/online-degree-programs-shardaonline.webp"
                      alt=""
                      class="w-[390px] h-[200px]"
                    />
                  </div>
                  <div>
                    <h2
                      class="text-[20px] lg:text-[28px] text-white font-medium"
                    >
                      Who is it for
                    </h2>
                    <p
                      class="text-[16px] lg:opacity-75 text-white lg:w-[196px]"
                    >
                      Students who are about to graduate from their course and
                      want to pursue their dream jobs
                    </p>
                  </div>
                  <div>
                    <h2
                      class="text-[20px] lg:text-[28px] text-white font-medium"
                    >
                      What you get
                    </h2>
                    <ul>
                      <li
                        class="flex gap-3 items-center text-white lg:opacity-75"
                      >
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Career mentorship
                      </li>
                      <li
                        class="flex gap-3 items-center text-white lg:opacity-75"
                      >
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Further studies guidance
                      </li>
                      <li
                        class="flex gap-3 items-center text-white lg:opacity-75"
                      >
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Resume building
                      </li>
                      <li
                        class="flex gap-3 items-center text-white lg:opacity-75"
                      >
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Entrepreneur perspectives
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="py-4"
              v-if="selectedcareer === 'Guidance for Further Education'"
            >
              <div class="flex flex-col gap-5">
                <h2
                  class="text-[20px] lg:text-[30px] text-[#0CB1EF] font-medium lg:font-semibold"
                >
                  Guidance for Further Education
                </h2>
                <p class="text-white text-[16px]">
                  The process of learning never stops, and we’re here to help
                  you take the next step in your academic journey.
                </p>
                <div
                  class="flex flex-col gap-4 lg:flex-row lg:gap-[80px] lg:items-center"
                >
                  <div>
                    <img
                      src="@/assets/home/career/online-degree-programs-shardaonline.webp"
                      alt=""
                      class="w-[390px] h-[200px]"
                    />
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">Who is it for</h2>
                    <p
                      class="text-[16px] lg:opacity-75 text-white lg:w-[196px]"
                    >
                      Students who are about to graduate from their course and
                      want to pursue further educational avenues
                    </p>
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">What you get</h2>
                    <ul>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Comprehensive guidance
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Expert advice
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Personalised support
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Dedicated counselling
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4" v-if="selectedcareer === 'Master Classes'">
              <div class="flex flex-col gap-5">
                <h2
                  class="text-[20px] lg:text-[30px] text-[#0CB1EF] font-medium lg:font-semibold"
                >
                  Master Classes
                </h2>
                <p class="text-white text-[16px]">
                  Get a competitive edge by gaining in-depth knowledge and
                  learning specialised skills from industry experts.
                </p>
                <div
                  class="flex flex-col gap-4 lg:flex-row lg:gap-[80px] lg:items-center"
                >
                  <div>
                    <img
                      src="@/assets/home/career/online-degree-programs-shardaonline.webp"
                      alt=""
                      class="w-[390px] h-[200px]"
                    />
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">Who is it for</h2>
                    <p
                      class="text-[16px] lg:opacity-75 text-white lg:w-[196px]"
                    >
                      Students who are currently pursuing courses, and wish to
                      gain additional knowledge on the subject matter
                    </p>
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">What you get</h2>
                    <ul>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Insights from industry experts
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Learn specialised topics
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Practical knowledge
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Unparalleled expertise
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4" v-if="selectedcareer === 'Profile Building'">
              <div class="flex flex-col gap-5">
                <h2
                  class="text-[20px] lg:text-[30px] text-[#0CB1EF] font-medium lg:font-semibold"
                >
                  Profile Building
                </h2>
                <p class="text-white text-[16px]">
                  Learn the art of presenting yourself in the best way, and
                  stand out from the crowd with our profile building guidance.
                </p>
                <div
                  class="flex flex-col gap-4 lg:flex-row lg:gap-[50px] lg:items-center"
                >
                  <div>
                    <img
                      src="@/assets/home/career/online-degree-programs-shardaonline.webp"
                      alt=""
                      class="w-[390px] h-[200px]"
                    />
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">Who is it for</h2>
                    <p
                      class="text-[16px] lg:opacity-75 text-white lg:w-[196px]"
                    >
                      Students who are about to graduate from their course and
                      want to showcase their best versions in the job market
                    </p>
                  </div>
                  <div>
                    <h2 class="text-[28px] text-white">What you get</h2>
                    <ul>
                      <li class="flex gap-3 items-center  text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Learn effective self-representation
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Find impactful ways to showcase your skills
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Ways to elevate your online presence
                      </li>
                      <li class="flex gap-3 items-center text-white lg:opacity-75">
                        <img
                          src="@/assets/home/career/checIcons.svg"
                          alt=""
                          srcset=""
                          class="w-[10px] h-[8px]"
                        />Know how to unlock new opportunities
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CareerView",
  data() {
    return {
      careers: [
        "Career Support",
        "Guidance for Further Education",
        "Master Classes",
        "Profile Building",
      ],
      selectedcareer: "Career Support",
    };
  },
};
</script>

<style></style>
