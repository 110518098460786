<template>
  <div class="!z-50 flex justify-center items-center w-full h-full">
    <!-- Popup Modal -->
    <div class="text-[13px] !z-999" v-if="isDownloadPopupOpen">
      <h2 class="font-bold text-3xl text-center text-[#18479E]">
        Download <span class="text-[#EC027C]">Brochure</span>
      </h2>
      <div v-if="showForm">
        <!-- Form -->
        <form class="space-y-3 p-4 font-normal text-center" @submit.prevent="submitDownloadForm($event)">
          <input type="text" v-model="formData.name" name="name" autocomplete="off"
            class="p-2 rounded-full w-full border border-[#ccc] outline-[#EC027C]" maxlength="255" minlength="1"
            placeholder="Name *" required />

          <input type="email" v-model="formData.email" name="email" placeholder="Enter Email Address *"
            class="p-2 rounded-full w-full border border-[#ccc] outline-[#EC027C]" required />

          <input id="phone" type="tel" v-model="formData.phoneNumber" name="phone" maxlength="10" placeholder="Mobile*"
            pattern="[5-9]{1}[0-9]{9}" oninput="this.value = this.value.replace(/[^0-9]/g,'');"
            class="p-2 rounded-full w-full border border-[#ccc] outline-[#EC027C]"
            title="Phone number must start with 5-9 and be 10 digits long" required />

          <button type="submit"
            class="submit-btn w-max text-white font-semibold bg-[#EC027C] hover:bg-[#ca9216] rounded-full px-4 py-2 m-auto"
            :class="{ 'cursor-pointer': verified, 'opacity-50 cursor-not-allowed': !verified }" :disabled="!verified">
            Submit & Download
          </button>
          <!-- Error Message -->
          <p v-if="errorMessage" class="text-red-600 mt-2">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
    <div v-else class="mt-4">
      <h3 class="text-xl font-bold text-600 text-[#ec027c]">Thank you for your submission!</h3>
      <p>Your brochure download will begin shortly.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DownloadBrochureView",
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDownloadPopupOpen: true,
      showForm: true,
      errorMessage: "",
      formData: {
        name: "",
        email: "",
        phoneNumber: ""
      },
      // Additional tracking fields
      utmSource: "",
      utmSourceCampaign: "",
      utmSourceMedium: "",
      utmAdgroupid: "",
      utmCampaignid: "",
      utmCreativeid: "",
      utmDevice: "",
      utmKeyword: "",
      utmMatchtype: "",
      utmNetwork: "",
      utmPlacement: "",
      Gclid: ""
    };
  },
  computed: {
    verified() {
      return (
        this.formData.name &&
        this.formData.email &&
        this.formData.phoneNumber &&
        this.formData.phoneNumber.length === 10
      );
    }
  },
  methods: {
    async submitDownloadForm(el) {
      el.preventDefault();
      // Clear any previous error message
      this.errorMessage = "";
      if (this.verified) {
        const dataJson = [
          { Attribute: "FirstName", Value: this.formData.name },
          { Attribute: "EmailAddress", Value: this.formData.email },
          { Attribute: "Phone", Value: this.formData.phoneNumber },
          { Attribute: "mx_utm_Source", Value: this.utmSource },
          { Attribute: "SourceCampaign", Value: this.utmSourceCampaign },
          { Attribute: "SourceMedium", Value: this.utmSourceMedium },
          { Attribute: "mx_utm_Adgroupid", Value: this.utmAdgroupid },
          { Attribute: "mx_utm_Campaignid", Value: this.utmCampaignid },
          { Attribute: "mx_utm_Creativeid", Value: this.utmCreativeid },
          { Attribute: "mx_utm_Device", Value: this.utmDevice },
          { Attribute: "mx_utm_Keyword", Value: this.utmKeyword },
          { Attribute: "mx_utm_Matchtype", Value: this.utmMatchtype },
          { Attribute: "mx_utm_Network", Value: this.utmNetwork },
          { Attribute: "mx_utm_Placement", Value: this.utmPlacement },
          { Attribute: "mx_Gclid", Value: this.Gclid },
          { Attribute: "mx_State", Value: "null" },
          { Attribute: "mx_City", Value: "null" },
          { Attribute: "mx_Program", Value: "null" },
          { Attribute: "mx_Elective", Value: "null" }
        ];

        // console.log("Payload for brochure download:", dataJson);
        try {
          const response = await axios.post("https://api.shardaonline.online/api/capture-lead/", dataJson);
          // console.log("Response received:", response);
          // Check for success status using the external_response
          if (response.data.external_response && response.data.external_response.Status === 'Success') {
            // Reset form data and hide form
            this.formData = { name: "", email: "", phoneNumber: "" };
            this.showForm = false;
            // Trigger the brochure download
            this.downloadBrochure();
          } else {
            // Set the error message from the API response if available
            this.errorMessage = response.data.external_response?.ExceptionMessage || "There was an issue capturing your details. Please try again.";
          }
        } catch (error) {
          console.error("Error submitting form:", error.response?.data || error.message);
          this.errorMessage = error.response?.data.external_response?.ExceptionMessage || "An error occurred. Please try again.";
        }
      } else {
        this.errorMessage = "Please verify your details before submitting.";
      }
    },
    downloadBrochure() {
      console.log("Attempting to open brochure in new tab from URL:", this.url);
      // Create a link element, set its target to _blank, and simulate a click.
      const link = document.createElement("a");
      link.href = this.url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.isDownloadPopupOpen = false;
    }
  }
};
</script>

<style scoped>
/* Modal styling */
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

/* Input styling */
input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

/* Button styling */
.submit-btn {
  background: #EC027C;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background: #ca9216;
}

.text-red-600 {
  color: #e3342f;
}

.mt-2 {
  margin-top: 0.5rem;
}
</style>
