<template>
  <div class="py-10 lg:py-5 lg:pt-12 px-5 lg:px-8 bg-gradient-to-b from-[#ffffff] via-[#ffffff] to-[#f0f0f0]">
    <div class="w-full max-w-6xl mx-auto lg:px-4">
      <div class="flex flex-col gap-3 lg:gap-6">
        <h2 class="text-[30px] lg:text-[60px] text-left text-[#18479E]">
          Fee & <span class="text-[#EC027C]">Eligibility</span>
        </h2>
        <div class="flex gap-3 lg:gap-5">
          <div v-for="tab in tabs" @click="activeTab = tab" :key="tab" class="flex">
            <button :class="{
              'text-[#18479E] border-b border-[#18479E] font-bold':
                activeTab === tab,
              'text-[#EC027C] border-b border-[#EC027C] font-medium':
                activeTab !== tab,
            }" class="text-[17px] lg:text-[28px] pb-1">
              {{ tab }}
            </button>
          </div>
        </div>
        <!-- <div class="w-full lg:w-[80%] min-h-[300px] lg:h-[350px]"> -->
        <div class="w-full min-h-[290px] lg:h-[290px]">
          <!-- active tab is fee structure -->
          <div v-if="activeTab === 'Fee Structure'" class="flex flex-col gap-3 lg:flex-row lg:gap-5">
            <div class="flex flex-col gap-3 lg:gap-5 w-full lg:pr-7">
              <div class="flex flex-col gap-3">
                <h3 class="text-left text-[20px] lg:text-[24px] text-[#18479E]">
                  Indian Students
                </h3>
                <p class="text-left text-[14px] text-[#000000] font-bold">
                  For Indian National & SAARC Nations
                </p>
              </div>
              <div class="flex flex-col gap-3 w-full lg:flex-row lg:justify-between">
                <div v-if="$route.path !== '/online-pgd/banking-and-financial-services'" class="flex flex-col gap-3">
                  <h3 class="text-left text-[18px] text-[#000000] font-medium">
                    Semester Fee Plan
                  </h3>
                  <p class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatCurrency(elective.indian_semester_fee) }}
                  </p>
                </div>
                <div class="flex flex-col gap-3">
                  <!-- <h3 class="text-left text-[18px] text-[#000000] font-medium">
                    Annual Fee Plan
                  </h3> -->
                  <h3 v-if="$route.path === '/online-pgd/banking-and-financial-services'"
                    class="text-left text-[18px] text-[#000000] font-medium">
                    Total Fee Plan
                  </h3>
                  <h3 v-else class="text-left text-[18px] text-[#000000] font-medium">
                    Annual Fee Plan
                  </h3>

                  <p v-if="$route.path === '/online-pgd/banking-and-financial-services'" class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatCurrency(elective.indian_total_fee) }}
                  </p>
                  <p v-else class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatCurrency(elective.indian_annual_fee) }}
                  </p>
                </div>
                <div v-if="$route.path !== '/online-pgd/banking-and-financial-services'" class="flex flex-col gap-3">
                  <h3 class="text-left text-[18px] text-[#000000] font-medium">
                    Total Fee Plan
                  </h3>
                  <p class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatCurrency(elective.indian_total_fee) }}
                  </p>
                </div>
              </div>
              <div v-if="
                $route.path !== '/online-pgd/banking-and-financial-services'
              " class="flex flex-col gap-2 text-left text-[12px] lg:text-[14px]">
                <p class="text-black font-semibold">Note</p>
                <ul class="list-none">
                  <li>
                    University Registration Fee:
                    {{
                      formatCurrency(
                        elective.indian_university_registration_fee
                      )
                    }}
                  </li>
                  <li>
                    Examination Fee per year:
                    {{ formatCurrency(elective.indian_exam_fee) }}
                  </li>
                  <li>
                    Registration Fee for SAARC countries:
                    {{
                      formatCurrency(elective.indian_registration_fee_for_saarc)
                    }}
                  </li>
                  <li>
                    Exam Fee Per Year for SAARC countries:
                    {{ formatCurrency(elective.indian_exam_fee_for_saarc) }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="hidden lg:block bg-[#18479E] w-[2px] h-[270px]" v-if="
              $route.path !== '/online-pgd/banking-and-financial-services'
            "></div>
            <div class="flex flex-col gap-3 lg:gap-5 w-full lg:pl-7" v-if="
              $route.path !== '/online-pgd/banking-and-financial-services'
            ">
              <div class="flex flex-col gap-3">
                <h3 class="text-left text-[20px] lg:text-[24px] text-[#18479E]">
                  Foreign Students
                </h3>
                <p class="text-left text-[14px] text-[#000000] font-bold">
                  For Foreign Students
                </p>
              </div>
              <div class="flex flex-col gap-3 w-full lg:flex-row lg:justify-between">
                <div class="flex flex-col gap-3">
                  <h3 class="text-left text-[18px] text-[#000000] font-medium">
                    Semester Fee Plan
                  </h3>
                  <p class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatUsdCurrency(elective.foregin_semester_fee) }}
                  </p>
                </div>
                <div class="flex flex-col gap-3">
                  <h3 v-if="$route.path === '/online-pgd/banking-and-financial-services'"
                    class="text-left text-[18px] text-[#000000] font-medium">
                    Semester Fee Plan
                  </h3>
                  <h3 v-else class="text-left text-[18px] text-[#000000] font-medium">
                    Annual Fee Plan
                  </h3>
                  <!-- <p v-if="$route.path === '/online-pgd/banking-and-financial-services'" class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatUsdCurrency(elective.foregin_annual_fee) }}
                  </p> -->
                  <p class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatUsdCurrency(elective.foregin_annual_fee) }}
                  </p>
                </div>
                <div class="flex flex-col gap-3">
                  <h3 class="text-left text-[18px] text-[#000000] font-medium">
                    Total Fee Plan
                  </h3>
                  <p class="text-left text-[30px] text-[#EC027C] font-bold">
                    {{ formatUsdCurrency(elective.foregin_total_fee) }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-2 text-left text-[12px] lg:text-[14px]">
                <p class="text-black font-semibold">Note</p>
                <ul class="list-none">
                  <li>
                    University Registration Fee:
                    {{
                      formatUsdCurrency(
                        elective.foregin_university_registration_fee
                      )
                    }}
                  </li>
                  <li>
                    Examination Fee per year:
                    {{ formatUsdCurrency(elective.foregin_exam_fee) }} is
                    applicable
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- active tab is Eligibility -->
          <div v-if="activeTab === 'Eligibility'"
            class="h-full w-full flex flex-col gap-3 lg:flex-row items-center lg:gap-5">
            <div class="flex flex-col gap-5 lg:flex-row items-center w-full h-full">
              <div class="w-full pt-4 lg:pt-0 lg:w-1/2">
                <img src="@/assets/elective/eligibility.webp" alt="eligibility" />
              </div>
              <div class="w-full lg:w-1/2 text-left flex justify-center items-center">
                <p class="text-lg">
                  {{ stripHtmlTags(elective.eligibility) }}
                </p>
              </div>
            </div>
          </div>
          <!-- active tab is Payment mode -->
          <div v-if="activeTab === 'Payment mode'" class="flex flex-col gap-3">
            <div class="text-[20px] text-[#18479E]">Payment Mode</div>
            <div>
              <div class="flex items-start gap-x-4">
                <div class="mt-2">
                  <img src="@/assets/elective/checIcons.svg" alt="checkIcon" class="w-[.6rem]" />
                </div>
                <div class="text-[#000000] text-md w-[18rem] md:w-[30rem] lg:w-max lg:text-lg lg:font-medium">
                  Students should pay the prescribed fees on the online portal
                  at the time of admission.
                </div>
              </div>

              <div class="flex items-start gap-x-4 lg:mt-4">
                <div class="mt-2">
                  <img src="@/assets/elective/checIcons.svg" alt="checkIcon" class="w-[.6rem]" />
                </div>
                <div class="text-[#000000] text-md w-[18rem] md:w-[25rem] lg:w-[35rem] lg:text-lg lg:font-medium">
                  Fees will be accepted online on the admission portal via Debit
                  Card/Credit Card/UPI/Wallets/Net Banking etc.
                </div>
              </div>

              <div class="flex items-start gap-x-4 lg:mt-4">
                <div class="mt-2">
                  <img src="@/assets/elective/checIcons.svg" alt="checkIcon" class="w-[.6rem]" />
                </div>
                <div class="text-[#000000] text-md w-[18rem] md:w-[25rem] lg:w-[35rem] lg:text-lg lg:font-medium">
                  Care should be taken to ensure that payments are not made to
                  unauthorized persons or on any unauthorized website or
                  link.The University will not be responsible for any lapse in
                  this matter.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeesView",
  props: ["elective"],
  data() {
    return {
      tabs: ["Fee Structure", "Eligibility", "Payment mode"],
      activeTab: "Fee Structure",
      programOverView: "",
    };
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value);
    },
    formatUsdCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value);
    },
    stripHtmlTags(input) {
      if (typeof input === "string") {
        this.programOverView = input.replace(/<\/?[^>]+(>|$)/g, "");
      } else {
        this.programOverView = "";
      }
      return this.programOverView;
    },
  },
};
</script>

<style></style>
