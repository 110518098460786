<template>
  <div class="px-5 lg:px-8">
    <div class="max-w-6xl mx-auto py-5 lg:py-40">
      <div class="flex flex-wrap lg:flex-nowrap">
        <h2
          class="text-[30px] md:text-[45px] xl:text-[60px] w-full textAnimation"
          :class="{ show: titleVisible }"
          ref="title"
        >
          <span class="text-[#18479E]">Program </span>
          <span class="text-[#EC027C]">Highlights</span>
        </h2>
        <div
          class="w-full flex flex-col gap-2 mt-4 relative h-[80px] lg:h-[100px] overflow-hidden"
        >
          <div
            v-for="(highlight, index) in Highlights"
            :key="index"
            :id="'info' + index"
            class="highlight px-4 absolute opacity-0"
          >
            <div class="flex gap-7 lg:items-center">
              <img
                src="@/assets/elective/Path 1302@2x.png"
                alt="star"
                class="w-[18px] h-[18px] lg:w-[33px] lg:h-[33px] mt-1 lg:mt-0"
              />
              <p class="text-[16px] lg:text-[23px] font-normal text-black">
                {{ highlight }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramHighlight",
  props: ["highlight_list"],
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
      titleVisible: false,
      Highlights: [],
    };
  },
  created() {
    this.Highlights = this.highlight_list.split("|");
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.startAnimation();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.stopAnimation();
  },
  methods: {
    handleScroll() {
      const titleElement = this.$refs.title;
      const rect = titleElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        this.titleVisible = true;
      } else {
        this.titleVisible = false;
      }
    },
    startAnimation() {
      this.intervalId = setInterval(this.nextHighlight, 2000); // Change highlight every 3 seconds
    },
    stopAnimation() {
      clearInterval(this.intervalId);
    },
    nextHighlight() {
      this.currentIndex = (this.currentIndex + 1) % this.Highlights.length;
      this.updateHighlights();
    },
    updateHighlights() {
      const elements = document.querySelectorAll(".highlight");
      elements.forEach((el, index) => {
        if (index === this.currentIndex) {
          el.classList.add("show");
        } else {
          el.classList.remove("show");
        }
      });
    },
  },
};
</script>

<style scoped>
.textAnimation {
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(-100%);
  opacity: 0;
}

.textAnimation.show {
  opacity: 1;
  transform: translateY(0);
}

.highlight {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(-100%);
  opacity: 0;
}

.highlight.show {
  transform: translateY(0%);
  opacity: 1;
}
</style>
